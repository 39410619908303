import { AuthenticationService } from '../../auth/_services';
import { Injectable } from '@angular/core';

@Injectable()
export class GlobalConstantService {
  public accessToken: string;
  //public baseUrl = 'http://heybaby-newapi.codiant.com/api/';
  //public baseUrl = "http://heybabyyapi.codiant.com/api/";
  public baseUrl = 'http://localhost:3000/api/';
  public Content_Type = 'application/x-www-form-urlencoded';
  public Content_Type_JSON = 'application/json';

  constructor(private authenticationService: AuthenticationService) {
    this.accessToken = this.authenticationService.getToken();
  }
}
