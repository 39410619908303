import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './auth/login';
import { AuthGuard } from './auth/_guards';
import { ForgotComponent } from './auth/forgot/forgot.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  {
    path: 'reset-password/:token',
    loadChildren: 'app/auth/reset/reset.module#ResetModule'
  },
  {
    path: 'reset-password',
    loadChildren: 'app/auth/reset/reset.module#ResetModule'
  },
  {
    path: '',
    loadChildren: 'app/admin/admin.module#AdminModule',
    canActivate: [AuthGuard]
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // enableTracing: false
      useHash: true,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
// export const routing = RouterModule.forRoot(appRoutes);