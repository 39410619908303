﻿import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../_services';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'hb-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotComponent implements OnInit, OnDestroy {
  model: any = {};
  bodyClass = 'login-page';
  body = document.getElementsByTagName('body')[0];
  forgetFormGroup: FormGroup;
  loading = false;
  @ViewChild('main') mainContent;
  @ViewChild('loginSection') loginSection;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
  ) {

    this.forgetFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

  }

  ngOnInit() {
    this.body.classList.add(this.bodyClass);
    this.setAuthPageHeight();
  }

  onForgotPassword() {

  }

  ngOnDestroy() {
    this.body.classList.remove(this.bodyClass);
  }

  onSubmit() {
    this.loading = true;
    this.authenticationService.forgetPassword(this.forgetFormGroup.controls['email'].value)
      .subscribe(result => {
        if (result.success === true) {
          this.toastr.success(result.message);
          // this.router.navigate(['/login']);
        } else {
          this.toastr.error(result.message);
        }
        this.loading = false;
      },
      (err) => {
        this.toastr.error(err.message);
        this.loading = false;
      })
  }

  setAuthPageHeight() {
    let window_height = window.innerHeight;
    const loginCmp = this.loginSection.nativeElement;
    const mainCmp = this.mainContent.nativeElement;
    mainCmp.style.minHeight = window_height + 'px';
    if (screen.width > 1200) {
      loginCmp.style.minHeight = window_height - 80 + 'px';
      loginCmp.style.marginTop = '40px';
    } else if (screen.width > 992) {
      loginCmp.style.minHeight = window_height - 80 + 'px';
      loginCmp.style.marginTop = '40px';
    } else if (screen.width > 767) {
      loginCmp.style.minHeight = window_height - 260 + 'px';
      loginCmp.style.marginTop = '20px';
    } else {
      setTimeout(function () {
        var sectionHeight = loginCmp.height();
        loginCmp.style.marginTop = Math.max(0, (window_height - sectionHeight) / 2) + 'px';
      }, 500);
    }
  }


}
