import { HttpHeaders, HttpParams } from "@angular/common/http";

export class RequestOptions {
  headers?: HttpHeaders;
  observe?: "body";
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: "json";
  withCredentials?: boolean;

  constructor(data: any) {
    this.headers = data.headers;
    this.params = data.params;
  }
}
