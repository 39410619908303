import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../component/loader/loader.component';
import { SearchfilterPipe } from '../pipe/searchfilter.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LoaderComponent,
    SearchfilterPipe
  ],
  exports: [CommonModule, LoaderComponent, SearchfilterPipe]
})

export class SharedModule { }
