import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RequestOptions } from '../../shared/service/request-option';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable()
export class AuthenticationService {
    public token: string;
    public userType: string;
    baseUrl = environment.baseUrl;
    constructor(private http: HttpClient, private router: Router, ) {
        this.token = null;
        this.userType = null;
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
        this.userType = currentUser && currentUser.user_type;
    }

    login(username: string, password: string) {
        this.clearData();
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('email', username);
        urlSearchParams.append('password', password);
        urlSearchParams.append('source', 'web');
        const body = urlSearchParams.toString();
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        const a = this.http.post(this.baseUrl + 'login', body, { headers });
        return a.pipe(map((response: any) => {
            // login successful if there's a jwt token in the response
            const data = response.data;
            const token = data.user_token.access_token;
            // console.log(data);
            if (token) {
                // set token property
                this.token = token;
                // store username and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(
                    'currentUser',
                    JSON.stringify({
                        user_type: data.user_type,
                        token: token,
                        first_name: data.first_name,
                        center_id: data.center ? data.center.id : 0,
                        name: data.first_name + ' ' + data.last_name,
                        id: data.id,
                        photo: data.photo,
                        email: data.email,
                        phone: data.mobile_number,
                        address: data.address_line2,
                        country: data.country,
                        state: data.state,
                        city: data.city,
                        zip: data.zip_code,

                    })
                );
            }
            return response.data;
        }));
        //  .catch(this.handleError);
    }

    clearData() {
        this.token = null;
        this.userType = null;
        localStorage.removeItem('currentUser');
    }

    isAuthenticated() {
        if (this.token) {
            return true;
        } else {
            return false;
        }
    }

    getToken() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return currentUser ? currentUser.token : false;
    }

    getUserDetail() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    logout() {
        const headers = this.getHeadersObject(null, true, false);
        const options = new RequestOptions({ headers: headers });
        const a = this.http.get(this.baseUrl + 'logout', options);
        return a.pipe(map((response: any) => {
            return response;
        }));
    }

    forgetPassword(email: string) {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('email', email);
        const body = urlSearchParams.toString();
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        const options = new RequestOptions({ headers: headers });
        const a = this.http.post(this.baseUrl + 'user/forgot-password', body, { headers });
        return a.pipe(map((response: any) => {
            return response;
        }));
        //  .catch(this.handleError);
    }

    resetPassword(token: string, password: string) {
        const body = {
            'token': token,
            'password': password
        };
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        const options = new RequestOptions({ headers: headers });
        console.log(this.baseUrl + 'user/reset-password', body, options);
        const a = this.http.post(this.baseUrl + 'user/reset-password', body, options);
        return a.pipe(map((response: any) => {
            return response;
        }));

        //  .catch(this.handleError);
    }

    changePassword(currentPassword: string, password: string) {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("current_password", currentPassword);
        urlSearchParams.append("new_password", password);
        const body = urlSearchParams.toString();
        const headers = this.getHeadersObject(null, true, true);
        const options = new RequestOptions({ headers: headers });
        const a = this.http.post(this.baseUrl + "user/change-password", body, options);
        return a.pipe(map((response: any) => {
            return response;
        }));

    }

    getHeadersObject(arry?: any, accessToken?: boolean, content_type?: boolean): HttpHeaders {
        let headers = new HttpHeaders();
        if (accessToken) {
            headers = headers.append(
                'accessToken', this.getToken()
            );
        }
        if (content_type) {
            headers = headers.append(
                'Content-Type', 'application/x-www-form-urlencoded'
            );
            headers.append('Content-Type', 'application/json');
        } else {
            headers = headers.append('Content-Type', 'application/json');
        }
        for (const key in arry) {
            if (arry.hasOwnProperty(key)) {
                const element = arry[key];
                headers = headers.set(key, arry[key]);
            }
        }
        return headers;
    }

    // private handleError(error: any) {
    //     return Observable.throw(error);
    // }
}
