import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
import { User } from '../_models';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

     addUser(user): Observable<any> {
    let url: string = 'http://localhost/laravel_angular/api/registration';
    let data = {
      name: user.username,
      email:user.email,
      password: user.password
      
    };
     console.log(data);
    return this.http.post<User>(url, data, httpOptions).pipe(
      tap((user: User) => this.log(`added user`)),
      catchError(this.handleError<User>('addUser'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
  }
}