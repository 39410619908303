import { Component, OnInit, ViewChild,  Directive, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, NgForm, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { SecurityService } from "../../shared/service/security.service";
import { AlertService, AuthenticationService } from '../_services';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'hb-auth',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
    errorClass = "red";
    bodyClass = "login-page";
    body = document.getElementsByTagName("body")[0];
    main = document.getElementById("#mainContent");
    @ViewChild("main") mainContent;
    @ViewChild("loginSection") loginSection;
    loading = false;
    loginForm: FormGroup;
    submitted = false;
    returnUrl: string;
    model: any = {};
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private authenticationService: AuthenticationService,
        private securityService: SecurityService,
        private alertService: AlertService) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        // reset login status
        // this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.body.classList.add(this.bodyClass);
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.setAuthPageHeight();
        this.setRemember();
    }

    ngOnDestroy() {
        this.body.classList.remove(this.bodyClass);
    }


    onSubmit(f: NgForm) {
        this.loading = true;
        this.authenticationService.login(f.value.email, f.value.password)
            .pipe(first())
            .subscribe(
            data => {
                if (data['status'] === 'active') {
                    this.setResetRemember();
                    this.router.navigate(["/"]);
                    this.toastr.success('login successfully');
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.toastr.error('Invaild email or password ');
                }
            },
            err => {
                this.loading = false;
                this.toastr.error('Invaild email or password ');
            });
    }

    setAuthPageHeight() {
        const window_height = window.innerHeight;
        const loginCmp = this.loginSection.nativeElement;
        const mainCmp = this.mainContent.nativeElement;
        mainCmp.style.minHeight = window_height + "px";
        if (screen.width > 1200) {
            loginCmp.style.minHeight = window_height - 80 + "px";
            loginCmp.style.marginTop = "40px";
        } else if (screen.width > 992) {
            loginCmp.style.minHeight = window_height - 80 + "px";
            loginCmp.style.marginTop = "40px";
        } else if (screen.width > 767) {
            loginCmp.style.minHeight = window_height - 260 + "px";
            loginCmp.style.marginTop = "20px";
        } else {
            setTimeout(function () {
                const sectionHeight = loginCmp.height();
                loginCmp.style.marginTop =
                    Math.max(0, (window_height - sectionHeight) / 2) + "px";
            }, 500);
        }
    }
    setResetRemember() {
          if (this.model.remember) {
            localStorage.setItem(
                "email",
                this.securityService.encode(this.model.email)
            );
            localStorage.setItem(
                "password",
                this.securityService.encode(this.model.password)
            );
            localStorage.setItem("remember", "true");
        } else {
            localStorage.email = "";
            localStorage.password = "";
            localStorage.remember = "";
        }
    }

    setRemember() {
        if (localStorage.getItem("remember") === "true") {
            this.model.email = this.securityService.decode(
                localStorage.getItem("email")
            );
            this.model.password = this.securityService.decode(
                localStorage.getItem("password")
            );
            this.model.remember = true;
        } else {
            this.model.email = "";
            this.model.password = "";
            this.model.remember = false;
        }
    }

}
