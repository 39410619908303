import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalConstantService } from "./shared/service/global-constant.service";
// used to create fake backend
import { fakeBackendProvider } from './auth/_helpers';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AlertComponent } from './auth/_directives';
import { AuthGuard } from './auth/_guards';
import { ErrorInterceptor } from './auth/_helpers';
import { AlertService, AuthenticationService, UserService } from './auth/_services';
import { LoginComponent } from './auth/login';
import { SharedModule } from './shared/module/shared.module';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { SecurityService } from "./shared/service/security.service";
import { FormsModule } from '@angular/forms';
@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        AppRoutingModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot({
            closeButton: true,
            progressBar:true,
            timeOut:2000
        }), // ToastrModule added
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        LoginComponent,
        ForgotComponent,
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        GlobalConstantService,
        SecurityService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      ],
    bootstrap: [AppComponent]
})

export class AppModule { }
