import { Component, HostListener } from '@angular/core';
import { AuthenticationService } from './auth/_services';
@Component({
    selector: 'hb-root',
    templateUrl: 'app.component.html'
})

export class AppComponent {
    constructor(private authenticationService: AuthenticationService) { }
    // @HostListener('window:unload')
    // private onUnload(): void {
    //     this.authenticationService.logout();
    // }
} 